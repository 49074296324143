import _react from "react";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t(_react);
}(window, function (e) {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var o = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) n.d(r, o, function (t) {
        return e[t];
      }.bind(null, o));
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "", n(n.s = 11);
  }([function (e, t, n) {
    e.exports = n(10)();
  }, function (t, n) {
    t.exports = e;
  }, function (e, t) {
    e.exports = function (e) {
      var t = "undefined" != typeof window && window.location;
      if (!t) throw new Error("fixUrls requires window.location");
      if (!e || "string" != typeof e) return e;
      var n = t.protocol + "//" + t.host,
          r = n + t.pathname.replace(/\/[^\/]*$/, "/");
      return e.replace(/url\s*\(((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*)\)/gi, function (e, t) {
        var o,
            i = t.trim().replace(/^"(.*)"$/, function (e, t) {
          return t;
        }).replace(/^'(.*)'$/, function (e, t) {
          return t;
        });
        return /^(#|data:|http:\/\/|https:\/\/|file:\/\/\/|\s*$)/i.test(i) ? e : (o = 0 === i.indexOf("//") ? i : 0 === i.indexOf("/") ? n + i : r + i.replace(/^\.\//, ""), "url(" + JSON.stringify(o) + ")");
      });
    };
  }, function (e, t, n) {
    var r = {},
        o = function (e) {
      var t;
      return function () {
        return void 0 === t && (t = e.apply(this || _global, arguments)), t;
      };
    }(function () {
      return window && document && document.all && !window.atob;
    }),
        i = function (e) {
      var t = {};
      return function (e) {
        if ("function" == typeof e) return e();

        if (void 0 === t[e]) {
          var n = function (e) {
            return document.querySelector(e);
          }.call(this || _global, e);

          if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
            n = n.contentDocument.head;
          } catch (e) {
            n = null;
          }
          t[e] = n;
        }

        return t[e];
      };
    }(),
        a = null,
        u = 0,
        s = [],
        c = n(2);

    function f(e, t) {
      for (var n = 0; n < e.length; n++) {
        var o = e[n],
            i = r[o.id];

        if (i) {
          i.refs++;

          for (var a = 0; a < i.parts.length; a++) i.parts[a](o.parts[a]);

          for (; a < o.parts.length; a++) i.parts.push(v(o.parts[a], t));
        } else {
          var u = [];

          for (a = 0; a < o.parts.length; a++) u.push(v(o.parts[a], t));

          r[o.id] = {
            id: o.id,
            refs: 1,
            parts: u
          };
        }
      }
    }

    function l(e, t) {
      for (var n = [], r = {}, o = 0; o < e.length; o++) {
        var i = e[o],
            a = t.base ? i[0] + t.base : i[0],
            u = {
          css: i[1],
          media: i[2],
          sourceMap: i[3]
        };
        r[a] ? r[a].parts.push(u) : n.push(r[a] = {
          id: a,
          parts: [u]
        });
      }

      return n;
    }

    function p(e, t) {
      var n = i(e.insertInto);
      if (!n) throw new Error("Couldn't find a style target. This probably means that the value for the 'insertInto' parameter is invalid.");
      var r = s[s.length - 1];
      if ("top" === e.insertAt) r ? r.nextSibling ? n.insertBefore(t, r.nextSibling) : n.appendChild(t) : n.insertBefore(t, n.firstChild), s.push(t);else if ("bottom" === e.insertAt) n.appendChild(t);else {
        if ("object" != typeof e.insertAt || !e.insertAt.before) throw new Error("[Style Loader]\n\n Invalid value for parameter 'insertAt' ('options.insertAt') found.\n Must be 'top', 'bottom', or Object.\n (https://github.com/webpack-contrib/style-loader#insertat)\n");
        var o = i(e.insertInto + " " + e.insertAt.before);
        n.insertBefore(t, o);
      }
    }

    function d(e) {
      if (null === e.parentNode) return !1;
      e.parentNode.removeChild(e);
      var t = s.indexOf(e);
      t >= 0 && s.splice(t, 1);
    }

    function h(e) {
      var t = document.createElement("style");
      return void 0 === e.attrs.type && (e.attrs.type = "text/css"), m(t, e.attrs), p(e, t), t;
    }

    function m(e, t) {
      Object.keys(t).forEach(function (n) {
        e.setAttribute(n, t[n]);
      });
    }

    function v(e, t) {
      var n, r, o, i;

      if (t.transform && e.css) {
        if (!(i = t.transform(e.css))) return function () {};
        e.css = i;
      }

      if (t.singleton) {
        var s = u++;
        n = a || (a = h(t)), r = b.bind(null, n, s, !1), o = b.bind(null, n, s, !0);
      } else e.sourceMap && "function" == typeof URL && "function" == typeof URL.createObjectURL && "function" == typeof URL.revokeObjectURL && "function" == typeof Blob && "function" == typeof btoa ? (n = function (e) {
        var t = document.createElement("link");
        return void 0 === e.attrs.type && (e.attrs.type = "text/css"), e.attrs.rel = "stylesheet", m(t, e.attrs), p(e, t), t;
      }(t), r = function (e, t, n) {
        var r = n.css,
            o = n.sourceMap,
            i = void 0 === t.convertToAbsoluteUrls && o;
        (t.convertToAbsoluteUrls || i) && (r = c(r));
        o && (r += "\n/*# sourceMappingURL=data:application/json;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(o)))) + " */");
        var a = new Blob([r], {
          type: "text/css"
        }),
            u = e.href;
        e.href = URL.createObjectURL(a), u && URL.revokeObjectURL(u);
      }.bind(null, n, t), o = function () {
        d(n), n.href && URL.revokeObjectURL(n.href);
      }) : (n = h(t), r = function (e, t) {
        var n = t.css,
            r = t.media;
        r && e.setAttribute("media", r);
        if (e.styleSheet) e.styleSheet.cssText = n;else {
          for (; e.firstChild;) e.removeChild(e.firstChild);

          e.appendChild(document.createTextNode(n));
        }
      }.bind(null, n), o = function () {
        d(n);
      });

      return r(e), function (t) {
        if (t) {
          if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap) return;
          r(e = t);
        } else o();
      };
    }

    e.exports = function (e, t) {
      if ("undefined" != typeof DEBUG && DEBUG && "object" != typeof document) throw new Error("The style-loader cannot be used in a non-browser environment");
      (t = t || {}).attrs = "object" == typeof t.attrs ? t.attrs : {}, t.singleton || "boolean" == typeof t.singleton || (t.singleton = o()), t.insertInto || (t.insertInto = "head"), t.insertAt || (t.insertAt = "bottom");
      var n = l(e, t);
      return f(n, t), function (e) {
        for (var o = [], i = 0; i < n.length; i++) {
          var a = n[i];
          (u = r[a.id]).refs--, o.push(u);
        }

        e && f(l(e, t), t);

        for (i = 0; i < o.length; i++) {
          var u;

          if (0 === (u = o[i]).refs) {
            for (var s = 0; s < u.parts.length; s++) u.parts[s]();

            delete r[u.id];
          }
        }
      };
    };

    var y = function () {
      var e = [];
      return function (t, n) {
        return e[t] = n, e.filter(Boolean).join("\n");
      };
    }();

    function b(e, t, n, r) {
      var o = n ? "" : r.css;
      if (e.styleSheet) e.styleSheet.cssText = y(t, o);else {
        var i = document.createTextNode(o),
            a = e.childNodes;
        a[t] && e.removeChild(a[t]), a.length ? e.insertBefore(i, a[t]) : e.appendChild(i);
      }
    }
  }, function (e, t) {
    e.exports = function (e) {
      var t = [];
      return t.toString = function () {
        return this.map(function (t) {
          var n = function (e, t) {
            var n = e[1] || "",
                r = e[3];
            if (!r) return n;

            if (t && "function" == typeof btoa) {
              var o = function (e) {
                return "/*# sourceMappingURL=data:application/json;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(e)))) + " */";
              }(r),
                  i = r.sources.map(function (e) {
                return "/*# sourceURL=" + r.sourceRoot + e + " */";
              });

              return [n].concat(i).concat([o]).join("\n");
            }

            return [n].join("\n");
          }(t, e);

          return t[2] ? "@media " + t[2] + "{" + n + "}" : n;
        }).join("");
      }, t.i = function (e, n) {
        "string" == typeof e && (e = [[null, e, ""]]);

        for (var r = {}, o = 0; o < (this || _global).length; o++) {
          var i = (this || _global)[o][0];
          "number" == typeof i && (r[i] = !0);
        }

        for (o = 0; o < e.length; o++) {
          var a = e[o];
          "number" == typeof a[0] && r[a[0]] || (n && !a[2] ? a[2] = n : n && (a[2] = "(" + a[2] + ") and (" + n + ")"), t.push(a));
        }
      }, t;
    };
  }, function (e, t, n) {
    (e.exports = n(4)(!1)).push([e.i, ".shuffle-text {\n  min-height: 1em;\n  display: inline-block;\n}\n\n.shuffle-text-char {\n  display: inline-block;\n  animation: 0.5s ease-in-out both rstCharAni;\n}\n@keyframes rstCharAni {\n  0% {\n    opacity: 0;\n    transform: translate(100%, 0) scale(3);\n  }\n\n  100% {\n    opacity: 1;\n    transform: translate(0, 0) scale(1);\n  }\n}\n", ""]);
  }, function (e, t, n) {
    var r = n(5);
    "string" == typeof r && (r = [[e.i, r, ""]]);
    var o = {
      hmr: !0,
      transform: void 0,
      insertInto: void 0
    };
    n(3)(r, o);
    r.locals && (e.exports = r.locals);
  }, function (e, t, n) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.genRandomChars = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 30,
          n = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      n = n.split("").sort(function () {
        return Math.random() - 0.5;
      });

      for (; n.length < t;) n = n.concat(n);

      return n.slice(0, t - 1).concat(e);
    };
  }, function (e, t, n) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var r = function () {
      function e(e, t) {
        for (var n = 0; n < t.length; n++) {
          var r = t[n];
          r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
        }
      }

      return function (t, n, r) {
        return n && e(t.prototype, n), r && e(t, r), t;
      };
    }(),
        o = u(n(1)),
        i = u(n(0)),
        a = n(7);

    function u(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }

    var s = function (e) {
      function t(e) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (t.__proto__ || Object.getPrototypeOf(t)).call(this || _global, e));

        return n._incIndex = function () {
          n.state.index >= n.state.chars.length - 1 ? clearInterval(n._indexTimer) : n.setState(function (e) {
            return {
              index: e.index + 1
            };
          });
        }, n.state = {
          index: 0,
          chars: (0, a.genRandomChars)(e.char, e.frames)
        }, n._indexTimer = null, n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(t, o.default.Component), r(t, [{
        key: "componentDidMount",
        value: function () {
          (this || _global)._indexTimer = setInterval((this || _global)._incIndex, (this || _global).props.frameTime);
        }
      }, {
        key: "componentWillUnmount",
        value: function () {
          clearInterval((this || _global)._indexTimer);
        }
      }, {
        key: "render",
        value: function () {
          var e = (this || _global).state,
              t = e.index,
              n = e.chars;
          return o.default.createElement("span", {
            className: "shuffle-text-char"
          }, n[t]);
        }
      }]), t;
    }();

    s.propTypes = {
      frames: i.default.number,
      frameTime: i.default.number
    }, t.default = s;
  }, function (e, t, n) {
    "use strict";

    e.exports = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
  }, function (e, t, n) {
    "use strict";

    var r = n(9);

    function o() {}

    e.exports = function () {
      function e(e, t, n, o, i, a) {
        if (a !== r) {
          var u = new Error("Calling PropTypes validators directly is not supported by the `prop-types` package. Use PropTypes.checkPropTypes() to call them. Read more at http://fb.me/use-check-prop-types");
          throw u.name = "Invariant Violation", u;
        }
      }

      function t() {
        return e;
      }

      e.isRequired = e;
      var n = {
        array: e,
        bool: e,
        func: e,
        number: e,
        object: e,
        string: e,
        symbol: e,
        any: e,
        arrayOf: t,
        element: e,
        instanceOf: t,
        node: e,
        objectOf: t,
        oneOf: t,
        oneOfType: t,
        shape: t,
        exact: t
      };
      return n.checkPropTypes = o, n.PropTypes = n, n;
    };
  }, function (e, t, n) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var r = function () {
      function e(e, t) {
        for (var n = 0; n < t.length; n++) {
          var r = t[n];
          r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
        }
      }

      return function (t, n, r) {
        return n && e(t.prototype, n), r && e(t, r), t;
      };
    }(),
        o = u(n(1)),
        i = u(n(0)),
        a = u(n(8));

    function u(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }

    n(6);

    var s = function (e) {
      function t(e) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t);

        var n = function (e, t) {
          if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return !t || "object" != typeof t && "function" != typeof t ? e : t;
        }(this || _global, (t.__proto__ || Object.getPrototypeOf(t)).call(this || _global, e));

        return n._pushChar = function () {
          n.state.content.length >= n.props.content.length ? clearInterval(n._pushCharTimer) : n.setState(function (e) {
            var t = n.props.content,
                r = e.content,
                o = r.length;
            return {
              content: r.concat(t.slice(o, o + 1))
            };
          });
        }, n.state = {
          content: []
        }, n._pushCharTimer = null, n;
      }

      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
      }(t, o.default.Component), r(t, [{
        key: "componentDidMount",
        value: function () {
          (this || _global)._pushCharTimer = setInterval((this || _global)._pushChar, (this || _global).props.charIncInterval);
        }
      }, {
        key: "componentDidUpdate",
        value: function (e) {
          var t = this || _global;
          e.content !== (this || _global).props.content && this.setState({
            content: []
          }, function () {
            clearInterval(t._pushCharTimer), t._pushCharTimer = setInterval(t._pushChar, t.props.charIncInterval);
          });
        }
      }, {
        key: "componentWillUnmount",
        value: function () {
          clearInterval((this || _global)._pushCharTimer);
        }
      }, {
        key: "render",
        value: function () {
          var e = (this || _global).state.content,
              t = (this || _global).props,
              n = t.content,
              r = t.charFrames,
              i = t.charFrameTime;
          return n && n.length ? o.default.createElement("span", {
            className: "shuffle-text"
          }, e.map(function (e, t) {
            return o.default.createElement(a.default, {
              key: t + e,
              char: " " === e ? "\xA0" : e,
              frames: r,
              frameTime: i
            });
          })) : null;
        }
      }]), t;
    }();

    s.defaultProps = {
      content: "",
      charIncInterval: 100,
      charFrames: 15,
      charFrameTime: 50
    }, s.propTypes = {
      content: i.default.string.isRequired,
      charIncInterval: i.default.number,
      charFrames: i.default.number,
      charFrameTime: i.default.number
    }, t.default = s;
  }]);
});
export default exports;